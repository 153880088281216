@charset "utf-8";
* {
    margin: 0;
    padding: 0;
}

body {
    background-color: #fdfdfd;
    font-size: 14px;
    font-family: 'Microsoft YaHei', 'Times New Roman', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -ms-user-select:text;
        user-select:text;
}

div { 
    -o-box-sizing: border-box; 
    -ms-box-sizing: border-box; 
    box-sizing: border-box; 
}

img{
	border:none;
}

/** 患者基本信息样式 **/
.doctor-case-wrap{
	width: 1170px;
	height: 290px;
	box-shadow: 0px 5px 30px 0px #dde1f5;
	border-radius: 4px;
	margin: 50px auto 20px auto;
	background-color: #fff;
}

.doctor-case-avator{
	width:340px;
	height: 290px;
	float: left;
}

.doctor-case-avator img{
	width:148px;
	height: 148px;
	margin: 40px 20px 20px 170px;
	border-radius: 150px;
	border: solid 1px #c9c9c9;
}

.doctor-case-name{
    width: 122px;
    font-size: 24px;
    line-height: 26px;
    color: #3c3d40;
    margin: 3px 58px 3px 184px;
    text-align: center;
}

.doctor-case-detail{
	width:830px;
	height: 290px;
	float:right;
}

.dcd-item-wrap{
	width: 750px;
    height: 124px;
    margin: 80px 0px 80px 72px;
    padding: 3px 0px;
}

.dcd-item{
    width: 354px;
    height: 36px;
    font-size: 16px;
    line-height: 36px;
    color: #3c3d40;
    display: inline-block;
}

.dcd-item label{
	width: 80px;
    display: block;
    float: left;
}

.dcd-item span {
	width: 260px;
    display: block;
    float: right;
}
/** 患者基本信息样式 **/

/** 标题 **/
.content_title{
	width: 1170px;
    padding: 20px 0px;
    color: #7a7a7a;
    font-size: 24px;
}

.content_title span{
	padding-left:5px;
    color: #9a9a9a;
    font-size: 14px;
}

.item_label{
	width: 230px;
    height: 12px;
    line-height: 11px;
    padding-left: 6px;
    border-left: 2px solid #8580d0;
    margin-bottom: 10px;
    color: #3c3d40;
    font-size: 14px;
}

.item_label .mandatory{
	color:red;
}

/** 标题结束 **/

/** 按钮开始  **/
/**
 yzm-btn 正常按钮
 yzm-btn-disable 上按钮disable
 yzm-btn-sm 小号按钮
 yzm-btn-sm-disable 上按钮disable
 yzm-upload-btn 上传按钮
 yzm-upload-btn-disable 上次按钮disable
 yzm-download-btn 下载按钮  ie8及一下不支持圆角
 yzm-download-btn-ct CT下载按钮
 yzm-inverse-btn 反选按钮
 yzm-inverse-btn-disable 反选按钮禁用
 yzm-input 输入框
  **/
input {
	outline: none;
}
.yzm-btn,.yzm-btn-sm{
    width: 160px;
    height: 37px;
    background-image: linear-gradient(90deg, #685fa9 0%, #8590f3 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#685fa9', endColorstr='#8590f3');
	background-color: #8590f3;
	font-size: 16px;
	line-height: 35px;
    text-align: center;
    word-spacing: 5px;
    text-indent: 5px;
    color: #ffffff;
    cursor: pointer;
    box-shadow: 0px 5px 10px 0px rgba(37, 66, 139, 0.3);
    border-radius: 18px;
    border: solid 1px #ffffff;
    behavior: url(../static/js/common/PIE.htc);
}

.yzm-btn-disable{
	width: 160px;
    height: 37px;
	background-color: #eeeeee;
	font-size: 16px;
	line-height: 35px;
    text-align: center;
    word-spacing: 5px;
    text-indent: 5px;
	color: #919090;
	cursor: not-allowed;
    border-radius: 18px;
	border: solid 1px #c9c9c9;
    behavior: url(../static/js/common/PIE.htc);
}

.yzm-btn:hover,.yzm-btn-sm:hover {
    background-image: linear-gradient(90deg, #837bbe 0%, #9ba4ff 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#837bbe', endColorstr='#9ba4ff');
    background-color: #9ba4ff;
}

.yzm-btn-sm {
    width: 100px;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    word-spacing: 1px;
	text-indent: 1px;
	line-height: 28px;
}

.yzm-btn-sm-disable{
	width: 100px;
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    word-spacing: 1px;
	text-indent: 1px;
	line-height: 28px;
	background-color: #eeeeee;
	color: #919090;
	border: solid 1px #c9c9c9;
	cursor: not-allowed;
	behavior: url(../static/js/common/PIE.htc);
}

.yzm-upload-btn {
	width: 100px;
	height: 30px;
	color: #4b467e;
	text-align:center;
	background-color: #f9f9fc;
	box-shadow: 0px 2px 5px 0px  rgba(37, 66, 139, 0.3);
	border-radius: 4px;
	border: solid 1px #4b467e;
	cursor:pointer;
	behavior: url(../static/js/common/PIE.htc);
	line-height: 28px;
	position: relative;
}
.yzm-upload-btn input{
	position: absolute;
    width: 100px;
    height: 30px;
    left: 0px;
    top: 0px;
    display: inline-block;
    outline: none;
    background-color: transparent;
    filter: alpha(opacity=0);
    -moz-opacity: 0;
    -khtml-opacity: 0;
	opacity: 0;
}

.yzm-upload-btn:hover {
	border-color:#4b467e;
	background-color: #f0f0f9;
	color:#4b467e;
}

.yzm-upload-btn-disable,.yzm-upload-btn-disable:hover {
	color: #919090;
	border-color:#c9c9c9;
	background-color: #f9f9fc;
	box-shadow: 0px 0px 0px 0px rgba(37, 66, 139, 0.3);
	cursor:default;
}

.yzm-download-btn,.yzm-download-btn-ct{
	width: 170px;
	height: 40px;
	color: #4b467e;
	padding-left:50px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #dcdcdc;
    cursor:pointer;
    background-image: url(/static/images/common/download-btn-icon-stl.png);
	background-position: 25px 5px;
	background-repeat: no-repeat;
}

.yzm-download-disable,.yzm-download-disable-ct{
	width: 170px;
	height: 40px;
	color: #919090;
	padding-left:50px;
	background-color: #eeeeee;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
    cursor:no-drop;
    background-image: url(/static/images/common/disable-btn-icon-stl.png);
	background-position: 25px 5px;
	background-repeat: no-repeat;
}

.yzm-download-btn:hover,.yzm-download-btn-ct:hover{
	background-color: #f4f4fa;
	border: solid 1px #4b467e;
}

.yzm-download-btn-ct{
	background-image:url(/static/images/common/download-btn-icon-ct.png);
}

.yzm-download-disable-ct{
	background-image:url(/static/images/common/disable-btn-icon-ct.png);
}

.yzm-inverse-btn,.yzm-inverse-btn-disable{
	width: 80px;
	height: 32px;
	border-radius: 4px;
	border: solid 1px #dcdcdc;
	color: #4b467e;
	background-color: #ffffff;
	cursor:pointer;
}

.yzm-inverse-btn:hover{
	border: solid 1px #9d97de;
	background-color: #f4f4fa;
}

.yzm-inverse-btn-disable,.yzm-inverse-btn-disable:hover {
	border: solid 1px #dcdcdc;
	color: #7a7a7a;
	cursor:default;
}

.yzm-input {
    color: #4b467e;
    width: 285px;
    height: 38px;
    line-height: 21px;/*解决光标高度*/
    font-size: 16px;
    font-weight: Bold;
    background-color: #e5e5e5;
    border-radius: 4px;
    border: solid 1px #e5e5e5;
    outline: none;
    padding-left: 14px;
    behavior: url(../static/js/common/PIE.htc);
}

.yzm-input-sm {
	width: 164px;
}

.yzm-input-sm:focus {
	width: 164px !important;
}

/*ie10 去除x和眼睛 hack*/
.yzm-input::-ms-clear,.yzm-input::-ms-reveal{display:none;}

.yzm-input:focus {
    width: 285px;
	height: 38px;
	border: solid 1px #9d97de;
}

.yzm-input:-ms-input-placeholder {
    font-weight: normal;
    color: #7a7a7a
}

.yzm-input::-webkit-input-placeholder {
    color: #7a7a7a;
    font-weight: normal;
}

/* Mozilla Firefox 19+ */
.yzm-input::-moz-placeholder {
    color: #7a7a7a;
    font-weight: normal;
}

/* Mozilla Firefox 4 to 18 */
.yzm-input:-moz-placeholder {
    color: #7a7a7a;
    font-weight: normal;
}

/* Internet Explorer 10-11 */
.yzm-input:-ms-input-placeholder {
    color: #7a7a7a;
    font-weight: normal;
}

/** 按钮结束 **/

/** 搜索开始 **/
.yzm-search{
	width:235px;
	height:40px;
	padding-left:15px;
	background-color: #ffffff;
	border-radius: 20px;
	overflow:hidden;
	position:relative;
	border: solid 1px #4b467e;
	behavior: url(../static/js/common/PIE.htc); 
}  

.yzm-search img{
	display:inline-block;
	float:left;
	width:22px;
	height:21px;
	margin-top:8px;
}

.yzm-search input{
	display:inline-block;
	float:left;
	width:136px;
	height:38px;
	padding:0px 6px;
	border:0px;
	outline:none;
	color: #919090;
}

.yzm-search input::-webkit-input-placeholder {
    color: #919090;
}

.yzm-search input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #919090;
}

.yzm-search input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #919090;
}

.yzm-search input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #919090;
}

.yzm-search .yzm-search_btn{
	position:absolute;
	top:0;
	right:0;
	width:50px;  
	height:38px;
	*height:44px;
	border-left:1px solid #4b467e; 
	line-height:38px;
	text-align:center; 
	cursor:pointer;
	color: #4b467e;
	background:url(/static/images/common/ss.png) no-repeat right center;
}  

.yzm-search .yzm-search_btn:active  {
    background-color: #e2e0f6;
    background-image: none;  
}
/**搜索结束**/

/**弹框开始**/
.yzm-alert-mask{
	width:100%;
	height:100%;
	position:fixed;
	left:0;
	top:0;
	background-color: rgba(0, 0, 0, 0.2);
	filter:progid:DXImageTransform.Microsoft.gradient(startcolorstr=#33000000,endcolorstr=#33000000);
	z-index:999;
}

.yzm-alert{
	width: 430px;
	min-height: 240px; 
	background-color: #ffffff;
	box-shadow: 0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
	-webkit-box-shadow:  0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
   -moz-box-shadow:  0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
	border-radius: 4px;
	-moz-border-radius: 4px; /* Firefox */
  	-webkit-border-radius: 4px; /* Safari 和 Chrome */
 	position:absolute;
	top:50%;
	left:50%;
	margin:-120px 0 0 -215px ;
}

.yzm-alert-max{
	width: 630px;
	min-height: 270px; 
	position:absolute;
	top:50%;
	left:50%;
	margin:-135px 0 0 -315px ;
}

.yzm-alert-title{
	height:80px;
	border-bottom:1px solid #dcdcdc; 
	padding:0px 30px;
}

.yzm-alert-title-text{
	float:left;
	max-width:550px;
	height:80px;
	text-align:left;
	line-height:80px;
	color: #7a7a7a;
	font-size: 24px;
}

.yzm-alert-title-text .title-e{
	font-size: 14px;
}

.yzm-alert-close{
	float:right;
	width:20px;
	height:80px;
}
 
.yzm-alert-close a{
	display:inline-block;
	margin-top:30px;
	width:20px;
	height:20px;
	background:url(/static/images/common/Pop-ups-close.png) no-repeat center;
}

.yzm-alert-content{
	width:100%;
	min-height:160px; 
}

.yzm-alert-max .yzm-alert-content{
	height:190px; 
}

.yzm-alert-msg{
	min-height:100px;  
	*min-height:60px;
	padding:20px 30px;
	font-size: 16px; 
	line-height: 20px;
	color: #3c3d40;  
	word-break: break-all;
	word-wrap: break-word;
} 

.contentClass{
	width:100%;
	height:100px;  
	padding:20px 30px;
	font-size: 16px; 
	line-height: 20px;
	color: #3c3d40;  
}

.yzm-alert-max .yzm-alert-msg{
	height:130px; 
	*height:90px;
}

.yzm-alert-btn{ 
	width:100%;
	height:60px; 
	text-align:center;
}

.yzm-alert-btn input{
	outline:none;
}

.yzm-alert-btn .input-left{
	margin-right:30px;
}

.yzm-alert-btn .input-none, .closeBtnHide{
	display:none;
}

.body_overall {
	overflow:hidden;
}  
/**弹框结束**/

/*图片弹出框*/
.yzm-img{
	display: inline-block;
    width: 272px;
	height: 153px;
	margin-left: 28px;
	margin-top: 20px;
	text-align: center;
	border-radius: 4px;
    border: solid 1px #dcdcdc;
}
.yzm-img-radius{
	border-radius: 4px;
}
.yzm-img img{
	max-width: 270px;
	max-height: 151px;
	vertical-align: middle;
}
.yzm-img:hover{
	background-color: rgba(255, 255, 255, 0.2);
	opacity: 0.2;
	cursor: pointer;
}
.yzm-img span{
	height: 100%;
    width: 0;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.yzm_pic_box{
	margin-top:10px;
} 

/**页面输入框**/
.yzm-case-input{
	width: 215px; 
	height: 32px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
	outline:none;
	text-align: left;
	padding-left:15px;
	color: #4b467e;
}

.yzm-case-input:focus,.yzm-case-input-center:focus,.yzm-case-input-small:focus{
	border-color:#9d97de;
	color: #4b467e;
}

.yzm-case-input-center{
	width: 230px; 
	height: 32px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
	outline:none;
	text-align:center;
	color: #4b467e;
}

.yzm-case-input-center+span,.yzm-case-input-small+span{
	text-align: center;
}

.yzm-case-input-small{
	width: 110px; 
	height: 32px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
	outline:none;
	text-align:center;
	color: #4b467e;
}

.yzm-case-input:-ms-input-placeholder,.yzm-case-input-center:-ms-input-placeholder,.yzm-case-input-small:-ms-input-placeholder {
    font-weight: normal;
     color: #919090;
}

.yzm-case-input::-webkit-input-placeholder,.yzm-case-input-center::-webkit-input-placeholder,.yzm-case-input-small::-webkit-input-placeholder {
    color: #919090;
    font-weight: normal;
}

/* Mozilla Firefox 19+ */
.yzm-case-input::-moz-placeholder,.yzm-case-input-center::-moz-placeholder,.yzm-case-input-small::-moz-placeholder {
     color: #919090;
    font-weight: normal;
}

/* Mozilla Firefox 4 to 18 */
.yzm-case-input:-moz-placeholder,.yzm-case-input-center:-moz-placeholder ,.yzm-case-input-small:-moz-placeholder{
     color: #919090;
    font-weight: normal;
}

/* Internet Explorer 10-11 */
.yzm-case-input:-ms-input-placeholder,.yzm-case-input-center:-ms-input-placeholder,.yzm-case-input-small:-ms-input-placeholder {
     color: #919090;
    font-weight: normal;
}


/**页面输入框结束**/


/**日期输入框**/
.yzm-case-date{
	width: 230px; 
	height: 32px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
	outline:none;
	text-align:center;
	color: #4b467e;
}

.yzm-case-date-small{
	width: 108px; 
	height: 30px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #c9c9c9;
	outline:none;
	text-align:center;
	color: #4b467e;
}

.yzm-case-date:focus,.yzm-case-date-small:focus{
	border-color:#9d97de;
	color: #4b467e;
}

.yzm-case-date+span,.yzm-case-date-small+span{
	text-align: center;
}

.yzm-case-date:-ms-input-placeholder,.yzm-case-date-small:-ms-input-placeholder {
    font-weight: normal;
    color: #919090;
}

.yzm-case-date::-webkit-input-placeholder,.yzm-case-date-small::-webkit-input-placeholder {
    color: #919090;
    font-weight: normal;
}

/* Mozilla Firefox 19+ */
.yzm-case-date::-moz-placeholder,.yzm-case-date-small::-moz-placeholder {
    color: #919090;
    font-weight: normal;
}

/* Mozilla Firefox 4 to 18 */
.yzm-case-date:-moz-placeholder,.yzm-case-date-small:-moz-placeholder {
    color: #919090;
    font-weight: normal;
}

/* Internet Explorer 10-11 */
.yzm-case-date:-ms-input-placeholder,.yzm-case-date-small:-ms-input-placeholder {
    color: #919090;
    font-weight: normal;
}


/**性别选择框**/
.yzm-radio-initial {
	width: 60px;
	height: 32px;
	background-color: #ffffff;
	border-radius: 4px;
	border: solid 1px #a2a2a2;
	float:left;
	margin-right:20px;
	font-size: 16px;
	line-height: 30px;
	color: #3c3d40;
	text-align:center;
	cursor:pointer;
}

.yzm-radio-initial:hover{
	border-color:#a2a2a2;
	color: #3c3d40;
}

.yzm-radio-select{
	padding-right:20px;
	background:url(/static/images/common/gender.png) no-repeat 38px center;
	background-color: #4b467e;
	border-color:#4b467e;
	color: #ffffff;
}

.yzm-radio-select:hover{
	border-color:#4b467e;
	color: #ffffff;
}

/**jbx结束**/

/*病例列表左侧菜单 开始*/
.select_tit label,
.seek_tit label {
    line-height: 0px;
    color: #7a7a7a;
}

.select_tit {
    margin-bottom: 9px;
} 

.option_content {
    width: 235px;
    text-align: center;
    height: 60px;
    cursor: pointer;
    line-height: 60px;
}

.option_content p {
    float: left;
    margin-left: 10px;
}

.option_content div {
    width: 10px;
    height: 2px;
    background-color: #4b467e;
    float: left;
    margin-top: 29px;
    margin-left: 10px;
    opacity: 0;
}

.option_content:hover {
    background-color: #ebeaf6;
    border-radius: 4px;
}

.option_active {
    background-color: #ebeaf6;
    border-radius: 4px;
}

.option_active .option_sign {
    opacity: 1;
}

.option_content:hover label {
    color: #4b467e;
}

.option_content:hover .option_sign {
    opacity: 1;
}

.option_content label {
    font-size: 20px;
    line-height: 0px;
    cursor: pointer;
    color: #3c3d40;
}

.option_active  label{
    color: #4b467e;  
}

.option_content span { 
    color: #7a7a7a;
}

#process {
    color: #ff8a35; 
}
/*病例列表左侧菜单 结束*/


/*头像裁剪*/
.yzm-portrait{
	width: 630px;
	height: 380px; 
	background-color: #ffffff;
	box-shadow: 0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
	-webkit-box-shadow:  0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
   -moz-box-shadow:  0px 5px 30px 0px 
		rgba(128, 152, 211, 0.3);
	border-radius: 4px;
	-moz-border-radius: 4px; /* Firefox */
  	-webkit-border-radius: 4px; /* Safari 和 Chrome */
 	position:absolute;
	top:50%;
	left:50%;
	margin:-120px 0 0 -215px ;
}

.container{
	clear: both;
}
.modal-content .modal-body{
	padding: 26px 30px 24px;
	overflow: hidden;
}

.img-container{
	width:250px;
	height:250px;
	background-color: #f7f7f7;
	border:1px solid #000;
	text-align: center;
}

.img-container,
.zoom,
.preview {
	float: left;
}

.zoom{
	margin: auto 20px;
}

.img-demand{
	width: 240px;
	color: #3c3d40;
}

.img-select{
	overflow: hidden;
}
.img-preview {
	float: left;
	width: 80px;
	height: 80px;
	background-color: #f7f7f7;
	text-align: center;
	margin:10px 0 10px 10px;
	border-radius: 50%;
	overflow: hidden;
}

.img-preview > img {
	max-width: 100%;
}

.preview_btn{
	margin: 35px 0px 0px 20px;
}

.schedule{
	width: 8px;
	height: 170px;
	background-color: #e7e7e7;
	border-radius: 4px;
	margin: 10px 12px;
}

.mangify,
.shrink{
	width: 30px;
	height: 30px;
}

.submit{
    margin: 57px auto 0;
}

.dot{
	float: left;
	width: 12px;
	height: 12px;
	background-image: linear-gradient(0deg, #685fa9 0%, #8590f3 100%);
	border-radius: 50%;
	margin-left: -2px;
	margin-top: 79px;
}
.portrait-error{
	display: none;
	float: left;
    margin-top: 32px;
    margin-left: 74px;
}
.portrait-error span {
    width: 14px;
    height: 14px;
    font-size: 12px;
    line-height: 13px;
    border-radius: 11px;
    color: #ff8125;
    text-align: center;
    cursor: default;
    border: 1px solid #ff8125;
    display: inline-block;
    box-sizing: border-box;
}

.portrait-error label {
    line-height: 13px;
    color: #ff8a35;
    padding-left: 4px;
}

/*照片弹框*/
.photo_alert_mask{
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	background-color: rgba(0, 0, 0, 0.7);
	filter:progid:DXImageTransform.Microsoft.gradient(startcolorstr=#B2000000,endcolorstr=#B2000000);
	z-index:800;
}

.photo_alert{
	width:1474px;
	height:856px;
	position:absolute;
	left:50%;
	top:50%;
	margin:-428px -737px;
}

.photo_alert_large_pic{
	width:1474px;
	height:675px;
}

.large_pic_box{
	width:1200px;
	height:675px;
	float:left;
	overflow: hidden;
	position:relative;
}

.large_pic_list{
	height:675px;
	position:absolute;
	left:0px;
} 

.large_pic{
	width:1200px;
	height:675px;
	text-align:center;
	line-height:675px;
	font-size:90px;
	color:#fff;
	float:left;  
	line-height:675px;
}

.large_pic img{
	max-width: 1200px;
    max-height: 675px;
    vertical-align: middle;
    display: inline-block;
}

.photo_alert_previous, .photo_alert_next{ 
	width:137px; 
	height:675px;
	float:left;
	position:relative;
}

.photo_alert_previous #previous, .photo_alert_next #next{
	display:inline-block;
	width:37px;
	height:90px;
	position:absolute;
	top:50%; 
	margin:-50px 0px;
	cursor:pointer;
} 

.photo_alert_previous #previous img, .photo_alert_next #next img{
	width:37px;
	height:90px; 
}

.photo_alert_next{
	padding-left:100px;
}

.photo_alert_small_pic{
	height:140px; 
	margin-top:40px;
	text-align:center; 
}

.small_pic_list{
	padding:20px 15px;
	height:140px;
	background-color: rgba(255, 255, 255, 0.2);
	filter:progid:DXImageTransform.Microsoft.gradient(startcolorstr=#33ffffff,endcolorstr=#33ffffff);	
	behavior: url(../static/js/common/PIE.htc);
	border-radius: 4px;
	display:inline-block; 
	*display:inline; 
	*zoom:1;
}

.small_pic{
	width: 100px;
	height: 100px;
	behavior: url(../static/js/common/PIE.htc);
	border-radius: 4px;
	float:left;
	margin-right:30px;
	cursor:pointer;
	line-height:92px;
	border: solid 3px rgba(255, 255, 255,0);
}

.small_pic img{
	max-width: 92px;
    max-height: 92px;
    vertical-align: middle;
    display: inline-block;
}

.small_pic_active{
	border: solid 3px #f8d61b;
	line-height:92px;
}

.small_pic_last{
	margin-right:0px;
}

.photo_alert_btn{
	width:95px;
	height:135px;
	position:absolute;
	top:35px;
	right:0px;
}

.photo_alert_btn a{
	display:inline-block;
	width:50px;
	height:50px;
} 

.photo_alert_close_download{
	margin-top:35px;
}

/*消息 返回顶部按钮样式*/
.news-btn-box{
	width:60px;
	height:130px;
	position:absolute;
	right:-70px;   
	bottom:50px;    
}

 .back-top-btn, .news-frame-btn{
	text-align:center;
	line-height:60px;
	font-size: 14px;
 }

.news-frame-btn{
	width:60px;
	height:60px;
	margin-top:10px;
	background-image: linear-gradient(90deg, #675fa9 0%, #8590f3 100%) ;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#675fa9', endColorstr='#8590f3',GradientType=0 ); 
	box-shadow: 0px 5px 10px 0px 
		rgba(37, 66, 139, 0.3);
	border-radius: 3px;
	border: solid 1px #ffffff;
	cursor:pointer;
	color: #fff;
	position:relative;
	display:none;
}

.massage-number{
	display:inline-block;
	background-color: #e03232;
	font-size: 10px;
	color: #ffffff; 
	position:absolute;
	right:6px; 
	top:6px;  
	border-radius:100%;
	min-width:16px;
	min-hieght:16px;
	text-align:center;
	line-height:16px;
}

 .back-top-btn{
	width:60px;
	height:60px;
	box-shadow: 0px 5px 30px 0px 
		#dde1f5; 
	border-radius: 3px; 
	cursor:pointer; 
	color: #4b467e;
}

 .back-top-btn  img, .news-frame-btn img{
	*margin-top:expression((400 - this.height )/2); 
    vertical-align:middle;
 }


 .back-top-btn  .text_word, .news-frame-btn .text_word{
 	display:none;
	line-height:20px;
	padding:10px 15px;
}
 
 .back-top-btn:hover  img, .news-frame-btn:hover img{
	display:none;
 }
 
  .back-top-btn:hover  .text_word, .news-frame-btn:hover .text_word{
	display:block;
 }



/*聊天窗口*/
.chat-record-window-mask{
	width:100%;
	height:100%;
	position:fixed;
	top:0;
	left:0;
	background-color: rgba(0, 0, 0, 0.3);
	filter:progid:DXImageTransform.Microsoft.gradient(startcolorstr=#B2000000,endcolorstr=#B2000000);
	z-index:800;
	display:none;
}

.chat-record-window{
	width: 630px;
	height: 580px;
	background-color: #fdfdfd;
	box-shadow: 0px 5px 30px 0px rgba(10, 20, 50, 0.22);
	border-radius: 3px;
	position:absolute;
	left:50%;
	top:50%;
	margin:-290px -315px;
}

.chat-record-window-title{
	width: 630px;
	height: 60px;
	background-image: linear-gradient(90deg, #4b467e 0%, #455992 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b467e', endColorstr='#455992',GradientType=0 ); 
	z-index:800;
	border-radius: 3px 3px 0px 0px;
	padding-left:20px;
}

.chat-record-window-title-left{
	width: 90px;
	height: 60px;
	line-height:60px;
	font-size: 24px;
	color: #ffffff;
	float:left;
}

.chat-record-window-title-left span{
	font-size: 14px;
	color: #c6c9ed;
}

.chat-record-window-close{
	float:right;
	width:54px;
	height:60px;
	text-align:center;
	line-height:60px;
}

.close-btn{
	display:inline-block;
	width:14px;
	height:14px;
	background:url(/static/images/decorator/im-close-mormal.png) no-repeat center;
}

.close-btn:hover{
	background:url(/static/images/decorator/im-close-hover.png) no-repeat center;
}

.message-record{
	width:630px;
	height:398px;
}

.message-record-list{
	width: 630px;
    height: 398px;
}

.send-content{
	width:630px;
}

.textarea{
	width:630px;
}

.textarea textarea{
	width:600px;
	height:62px;
 	font-size: 14px;
	line-height: 20px;
	color: #3c3d40;
	outline:none;
	border:none;
	resize: none;
	background-color: #fdfdfd;
	padding:0px 10px 0px 20px; 
}

.textarea-border{
	width:630px;
	height:6px; 
	border-top:1px solid #d5dce5;
	cursor:s-resize;
}

textarea{
	width:630px;
	height:72px;
 	font-size: 14px;
	line-height: 20px;
	color: #3c3d40;
	outline:none;
	border:none;
	resize: none;
	background-color: #fdfdfd;
}

.send-out{
	width:630px;
	height:50px;
	padding-right:20px;
	padding-top: 10px;
}

.send-out .yzm-btn-sm{
	float:right;
}

.send-error-box{
	width:630px;
	height:30px;
}

.send-error{
	float:right;
	padding:0px 20px;
	background:url(/static/images/doctor-case/index/error.png) no-repeat left center;
	font-size: 14px;
	line-height: 30px;
	color: #ff8a35;
}

.message-remind{
	width:630px;
	height:40px;
	text-align:center;
	line-height:40px;
	font-size: 14px;
	color: #43a948;
	background-color: #edfaee;
	display:none;
}

.message-more{
	width:630px;
	height:60px;
	padding-top:20px;
	text-align:center;
}

.message-more a{
	display:inline-block;
	width:84px;
	height:22px;
	line-height:22px;
	text-align:center;
	font-size: 12px;
	color: #4b467e;
	background-color: #f4f3fc;
	border-radius: 11px;
}

.chat-record-item{
	padding-top:10px;
	width:630px;
	min-height:80px;
	padding-left:20px;
	padding-bottom:10px;
}

.chat-record-item-pic{
	width:70px;
	min-height:60px;
	float:left;
}

.chat-record-item-pic img{
	border-radius:100%;
	width:50px;
	height:50px;
}

.chat-record-item-content{
	width:500px;
	min-height:60px;
	float:left;
}

.chat-record-item-name-time{
	width:500px;
	height:24px;
}

.chat-record-item-user .chat-record-item-name-time{
	text-align:right;
}

.chat-record-item-name{
	font-size: 14px;
	color: #3c3d40;
}

.chat-record-item-time{
	font-size: 12px;
	color: #7a7a7a;
}

.message-box{
	display:inline-block;
	min-height:36px;
	padding:4px 10px;
	line-height:24px;
	font-size: 14px;
	color: #4b467e;
	border-radius: 10px; 
	border: solid 1px #d5dce5;
	position:relative;
	max-width:500px;
	word-wrap:break-word;
}

.message-box .imgBg{
	position:absolute;
	left:-10px;
	top:7px;
}

.message-box .seeImg{
	max-width: 280px;
    max-height: 280px;
    vertical-align: bottom;
}

.chat-record-item-user {
	padding-right:20px;
}

.chat-record-item-user .chat-record-item-pic, .chat-record-item-user  .chat-record-item-content{
	float:right;
}

.chat-record-item-user  .chat-record-item-pic{
	padding-left:20px;
}

.chat-record-item-user  .chat-record-item-content{
	text-align:left;
} 

.chat-record-item-user  .message-box-user{
	display:inline-block;
	min-height:36px;
	padding:4px 10px;
	line-height:26px;
	font-size: 14px;
	color: #ffffff;
	border-radius: 10px; 
	background-color: #4b467e;
	position:relative;
	max-width:500px;
	word-wrap:break-word;
	float:right;
}

.message-box-user .imgBg{
	position:absolute;
	right:-10px; 
	top:7px; 
}

.message-box-user .seeImg{
	max-width: 280px;
    max-height: 280px;
    vertical-align: bottom;
}